import { gql, useQuery } from '@apollo/client';
import { cloneList } from '../../../utils';

const CountryQuery = gql`
  query countryIcoCodes {
    countryIcoCodes {
      name
      code
    }
  }
`;

const useCountryCodes = () => {
  const { loading, data } = useQuery(CountryQuery);
  const countryCodes: { name: string; code: string }[] = cloneList(
    data?.countryIcoCodes,
  );
  const countryCodeMap: { [code: string]: string } = {};
  const countryCodeOptions = countryCodes.map(({ name, code }) => {
    const label = `${name} (${code})`;
    countryCodeMap[code] = label;
    return {
      value: code,
      label,
    };
  });

  return {
    loading,
    countryCodeMap,
    countryCodeOptions,
  };
};

export default useCountryCodes;
